.fund-raise-wrapper {
    margin:5px 5px 10px 5px;
    .fund-raise-bar-container {
        width:100%;
        background-color: lightgray;
        border-radius: 10px;
        .fund-raise-bar {
            transition: width 1.5s ease-in-out;
            background: linear-gradient(to right, #FFA600, #ec2c22);
            width:0%;
            border-radius: 10px;
        }
    }
}