// A mixin
.border-radius(@radius: 5px) {
  -webkit-border-radius: @radius;
     -moz-border-radius: @radius;
          border-radius: @radius;
} 

.center-image{
	display:block;
	margin: 0 auto;
	max-width:100%;
}

.little-star {
	margin:0px 0px 10px 0px; padding:0;
	background-image:url('../images/little-star.png') ;
    background-position: left center; 	background-repeat:no-repeat;
    padding-left:30px;  // according to your image with
  	min-height:25px; // according to your image height
  	display:block;
 	
}

.little-star-center {
	display:inline-block;
	margin:0px; padding:0;
	background-image:url('../images/little-star.png') ;
     background-position: left center; 	background-repeat:no-repeat;
    padding-left:30px;  // according to your image with
  	min-height:25px; // according to your image height
}

.seven-good-guys {
	position:relative;
	top:-90px;
	margin-bottom:-90px
}
//
#mr-tone-up-logo {
	width:168px;
	height:calc(180px + 25px);
	margin: 0 auto;
	#logo {	webkit-transition: transform 0.5s ease;
			transition: transform 0.5s ease;
	
			background-image:url('../images/mr-tone-up-logo.png?v1.3');
			position:relative;
			margin-top:100px;  	
			@media(min-width: @screen-sm){
   				margin-top:25px;  	
   			} 
			
			z-index:2;
			//width:168px;
			background-size:contain;
			height:180px;
			background-repeat:no-repeat;
	}
	#logo:hover {
		webkit-transform: scale(1,1) skew(0deg) rotate3d(0,1,0,180deg);
		transform: scale(1,1) skew(0deg) rotate3d(0,1,0,180deg);
		
	}
}

.menu-101 #mr-tone-up-logo #logo {
	margin-top:25px;  		
}

#footer-hat {
			background-image:url('../images/footer-hat.png');
			height:132px;
			width:200px;
			position:absolute;
			background-repeat:no-repeat;
			top:-40px;	
}

#footer-mr-tone {
			background-image:url('../images/footer-mr-tone.png');
			height:132px;
			width:200px;
			position:absolute;
			background-repeat:no-repeat;
			top:-55px;
			left:-10px;	
}

#footer-shirt {
			background-image:url('../images/footer-shirt.png');
			height:150px;
			width:200px;
			position:absolute;
			background-repeat:no-repeat;
			top:-45px;
			left:-10px;	
}

#footer-facebook {
			background-image:url('../images/footer-facebook.png');
			height:132px;
			width:200px;
			position:absolute;
			background-repeat:no-repeat;
			top:-20px;	
}



/*
@keyframes spin {
    from { transform: scale(1,1) skew(0deg) rotate3d(1,1,1,0deg); }
    to { transform: scale(1,1) skew(0deg) rotate3d(1,1,1,60deg); }
}
#logo:hover {
    animation: spin 0.5s forwards;
}
*/


.black-border {
	border-style:solid;
	border-width:2px;
	.border-radius( 15px );
	border-color:@dark-black;

	
}
.dotted {
	background-image:url('../images/dot.png');
	background-repeat: repeat-x;
	height:3px;
	width:100%;
	margin: 10px 0px 10px 0px;

}

.blog > div::after {
	background-image:url('../images/dot.png');
	background-repeat: repeat-x;
	height:3px;
	width:100%;
	margin: 10px 0px 10px 0px;
}

.dotted-white {
	//position:absolute;
	//top:109px;
	background-image:url('../images/white-dot.png');
	background-repeat: repeat-x;
	height:3px;
	width:100%;

}

.dotted-with-margin {
	margin-top:10px;
}

.right-good-guys {
	height:200px;
	img {
		//position:absolute;
		display:block;
		margin:auto;
		z-index:2;
		max-width:none;
		pointer-events:none;
		max-height: 200px;
	}
}

.right-mr-basket {
	.right-good-guys {height:250px;}
	img {right:20px;}	
	
}

.right-flex-proper-form {
	.right-good-guys {height:250px;}
	img {right:40px;}	
}

.right-mr-tone {
	.right-good-guys {height:250px;}
	img {right:50px;}	
}


.add-right-img-margin {
	margin-right:20px;
}

.add-bottom-img-margin {
	margin-bottom:20px;
}

/*

#spin{
    position:fixed;
    top:2%;
    left:0%;
   animation: spin 3s 1 ease-out;

}

@keyframes spin {
	0%    {transform: rotate3d(0,0,0,0deg);}
    10%  {transform: rotate3d(0,1,0,180deg);}
    20%    {transform: rotate3d(0,0,0,0deg);}
    30%  {transform: rotate3d(0,1,0,180deg);}
    40%    {transform: rotate3d(0,0,0,0deg);}
    50%  {transform: rotate3d(0,1,0,180deg);}
    60%    {transform: rotate3d(0,0,0,0deg);}
    70%  {transform: rotate3d(0,1,0,180deg);}
    80%    {transform: rotate3d(0,0,0,0deg);}
    90%  {transform: rotate3d(0,1,0,180deg);}
    100%    {transform: rotate3d(0,0,0,0deg);}

}
 */

 .blink {
	animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
	50% {
	  opacity: 0.5;
	}
  }

	.fiveStars {
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: @action-man;
		font-size: 25px;
		flex-wrap: wrap;

		img{ 
			display: block; 
			width:7%;
			padding:0px 10px;
		}
		@media ( max-width:800px) {
			img{
				width: 15%; 
			}
			.starTitle {
				width: 100%;
				text-align: center;

			}
		}

		padding:10px 10%; 
	}

	.item-image {
		display: block;
		margin: 0 auto;
		max-width: 100%;
		margin: 10px auto;
		float:none!important;
	}