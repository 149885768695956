.quote1WithImage,.quote1WithImageL,.quote1WithImageR {
  display: flex;
  justify-content: center;
  align-items: center;
  .qImage {
    width:25%;
    flex-shrink: 0;
    padding:10px 0px;
  }
  .qText,.qBuddyText {
    font-family: @open-sans;
    padding:5px 20px;
    font-style:italic;
    .author {
      font-size: 16px;
      font-weight: bold;
      font-style:normal;

      .authorPosition {
        font-weight: normal;
        font-style: italic;
      }
    }

  }  
  .qBuddyText {
    font-family: @gipsiero;
    font-size:35px;
    ;
  }

  @media ( max-width:700px) {
    flex-direction: column;

    .qImage {
      width:75%;
      img {
        display:block;
        margin:auto;
      }

    }

    .qText {
      text-align: center;
    }
    
  }
}


blockquote::before {
  content: open-quote;
  position: relative;
  left: -3px;
}
blockquote::after {
  content: close-quote;
}
blockquote {
  text-indent: -0.45em;
  margin-bottom: 23px;

  quotes: "“" "”" "‘" "’";

}