body#tinymce img {
  width: 80%!important;margin:auto;
  display: block;

}

body#tinymce hr#system-readmore {
  border: 1px solid red;
  border-radius: 5px;
  margin: 10px 0;
  padding: 0;
}