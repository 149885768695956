body, html {
	height:100%;
	background-color:@gray-lighter;
}

html{scroll-behavior:smooth}


.left-menu-level-1 {
	font-family: @left-menu-heading-font;
	font-size: @left-menu-heading-font-size;
}

.content {
	max-width: 900px;
	margin: auto;
}

#panel-background {
	min-height:100%;
	//background-image:url('../images/background-gradient.png');
	//background-size:2px 100%;
	background-image:url('../images/backgrounds/blurpage.webp');
	background-attachment: fixed;
    /* background-position: center; */
    background-size: cover;
	
}

#panel-container {
	max-width:@container-large-desktop;
	margin: 0 auto;
	height:100%;
	background-color:@white;	
	box-shadow: 1px 1px 40px #000;


}

#panel-row {
	overflow:hidden;
	min-height:80%;	
	background-color:transparent;
}

#panel-top-contact {
	//height:2.2rem;
	line-height:2.2rem;
	background-color:@panel-top-contact-background-color;
	color:@panel-top-contact-text-color;
	width:100%;
	font-family:@open-sans;
	#panel-top-contact-wrapper {
		height:100%;
		//width:100%;
		margin:0px auto;
		max-width:@container-large-desktop;
		div {
			text-align:right;
			padding-right:10px;
			padding-left:10px;
			a, a:hover, a:visited{
				color:@white;
			}
		}
	}
}

#panel-bottom {
	background-color:@panel-bottom-background-color;
	//margin-bottom: -99999px;
  //  padding-bottom: 99999px;
    background-image:url('../images/right-gradient.png');
    background-repeat:repeat-y;    
    background-size:100% 2px;    
	width:100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		/*
		> * {
			width: 20%;
			padding: 10px 10px;

			@media(max-width: 700px){
				width:33%;
			}

			@media(max-width: 500px){
				width:50%;
			}
		}
		*/
		> * {
			width:100%;
		}
		padding-bottom:60px;

		.custom {
			max-width: 900px;
			margin:auto;
		}


}

#panel-left {
	background-color:@panel-left-background-color;
	margin-bottom: -99999px;
    padding-bottom: 99999px;
    background-image:url('../images/left-gradient.png');
    background-repeat:repeat-y;    
	background-size:100% 2px;
	
	img {
		//border:2px solid black;
		border-radius: 15px;
	}
    
}

#panel-left-content {
	z-index:2;
}

#panel-content {	
	background-color:@panel-content-background-color;
    background-size:auto 268;
	z-index:2;
	padding-top:124px;
}

.category-all-videos  {
	h2 {
		margin-top:10px;
		text-align:center;
	}
	
	@media(min-width: @screen-sm){
		h2 {
			text-align:left;
			margin-top:0px;
		}
	}
	
	.row {
		margin-bottom:20px;
	}
	
	[class*="col-"] {
	img {
		width:100%;
		max-width:200px;
		margin:0 auto;
		display:block;
	}

}
	
}



#panel-blue-gradient {
	background-clip: content-box;
	background-image:url('../images/top-middle-gradient.png');
	//background-size:auto;
	//background-repeat: repeat-x;
	//position:absolute;
	width:100%;
		max-width:@container-large-desktop;
	background-size:2px 100%;

	padding:5px 10px 15px 10px;

	
	//height:268px;
	//height:205px;
	z-index:2;
	
}


#panel-orange-gradient {
	background-image:	 url('../images/top-orange-gradient.png');
	background-size: 2px 100%;
	background-repeat:  repeat;
	background-position:0% 0%;

	//position:absolute;
	width:100%;
		max-width:@container-large-desktop;

	
	padding:5px 10px 15px 10px;

	//height:268px;
	//height:124px;
	z-index:2;	
}

.menu-101 {
	@media(max-width: 600px){

		.page-heading{
			font-size: 8vw;;
		}
	}
}

.menu-113 {
	@media(max-width: 600px){

		.page-heading{
			font-size: 8vw;;
		}
	}
}

#panel-orange-gradient-with-mr-tone {

	padding:5px 10px 15px 10px;

	@media(min-width: 951px){

		background-image:	url(../images/mrTonePointingL.png), url('../images/top-orange-gradient.png');
		background-size:auto 90%, 2px 100%;
		background-repeat: no-repeat, repeat;
		background-position:4% center,0% 0%;
		h1 {
			padding-right:  200px; 
			padding-left: 200px; 
		}
	}

	@media(max-width: 950px){
		background-image:	 url('../images/top-orange-gradient.png');
		background-size: 2px 100%;
		background-repeat:  repeat;
		background-position:0% 0%;
		.siteTitle {
			display:none;
		}

	}

	//position:absolute;
	width:100%;
		max-width:@container-large-desktop;

	

	//height:268px;
	//height:124px;
	z-index:2;	

	@media(max-width: 400px){
		.t-no-break {
			white-space: initial!important;
		}
	}

}

#panel-green-gradient {
	background-image:url('../images/top-green-gradient.png');
	//background-size:auto;
	//background-repeat: repeat-x;
	//position:absolute;
	
	padding:5px 10px 15px 10px;
	
	width:100%;
		max-width:@container-large-desktop;
	background-size:2px 100%;
	
	//height:268px;
	//height:124px;
	z-index:2;	
}

#panel-light-blue-gradient {
	background-image:url('../images/top-light-blue-gradient.png');
	//background-size:auto;
	//background-repeat: repeat-x;
	//position:absolute;
	width:100%;
		max-width:@container-large-desktop;
	background-size:2px 100%;
	
	padding:5px 10px 15px 10px;

	//height:268px;
	//height:124px;
	z-index:2;	
}

#panel-dark-blue-gradient {
	background-image:url('../images/top-dark-blue-gradient.png');
	//background-size:auto;
	//background-repeat: repeat-x;
	//position:absolute;
	width:100%;
		max-width:@container-large-desktop;
	background-size:2px 100%;
	
	padding:5px 10px 15px 10px;


	//height:268px;
	//height:124px;
	z-index:2;	
}

#panel-light-brown-gradient {
	background-image:url('../images/top-light-brown-gradient.png');
	//background-size:auto;
	//background-repeat: repeat-x;
	//position:absolute;
	width:100%;
		max-width:@container-large-desktop;
	background-size:2px 100%;
	
	padding:5px 10px 15px 10px;

	//height:268px;
	//height:124px;
	z-index:2;	
}

#panel-red-gradient {
	background-image:url('../images/top-red-gradient.png');
	//background-size:auto;
	//background-repeat: repeat-x;
	//position:absolute;
	width:100%;
		max-width:@container-large-desktop;
	background-size:2px 100%;
	
	padding:5px 10px 15px 10px;

	//height:268px;
	//height:124px;
	z-index:2;	
}

#footer {
	background-color:@footer-background-color;
	a {
		text-decoration:none;
		color:@white;
		
	}
	h3 {
		font-family:@action-man;		
	}
	
	[class*="col-"] {
		h3,p {
			margin-left:60px;
		}
	}
}

.fill {
}

.tc-container {
	  display: table;
	  width:100%;
}

.tc-row {
	  display: table-row;
}

.tc-column {
	  display: table-cell;
	  width:33.3%;
}

.gradient-green-yellow {
	background-image:url('../images/green-yellow-gradient.png');
	background-repeat:repeat-x;    
    background-size:2px 100%;
	width:100%;
	min-height:100px;
}

#green-why {
	width:100%;
	padding: 0px 3% 80px 3%;
	.little-star, .little-star-center {
		font-family:@open-sans;
	  	font-size:13px;
	  	font-weight:bold;
	  	color:@white;
	  	text-decoration:underline;
	  	&:hover,&:active {
	  		
	  	}
	}
	.center {
		text-align:center;
	} 	  
	.row {
		[class*="col-"]{
		padding-left:0px;
		padding-right:0px;
		}
		
	}
}

.col-no-padding {
	padding:0px;
}

@media(min-width: @screen-sm){
  	 .col-no-left-padding {
		padding-left:0px;
	}
}

hr#system-readmore {
    display: block;
    border-width: 12px;
    border-color:#EEE;
    clear:both;
} 

hr#system-readmore:after { 
	content: "§ READMORE --- WHEN DRILLED DOWN TEXT BELOW IS SHOWN AND ABOVE HIDDEN --- "; 
	display: inline-block; 
	position: relative; 
	top: -0.7em; 
	font-size: 12px; 
	padding: 0 0.25em; 
	background: white;
}

[class*="col-"] {
	img {
		max-width:100%;
	}
}

.module-top-margin {
	margin-top:15px;
}

.donation-amount {
	max-width:100px;
}

#back-top {
	clear:both;
}

.flex-container {
	display: -webkit-box; /* wkhtmltopdf uses this one */
display:flex;	
} 

.inline-mobile {
	@media (max-width: 600px) {
		display:inline;
	
	}
}

.menu-image-1{
	max-width:160%!important; 

	@media (min-width: @screen-md) {
		right:25%;
		position: relative;
	}

	@media (max-width: @screen-sm-max) {
		max-width:140%!important; 
		position: relative;
		right:20%;


	}

	@media (max-width: @screen-xs-max) {
		position: initial;
		display:block;
		margin:auto;
	}
}

