#panel-content {
	padding:134px 5% 10px 5%;

}

@media (max-width:@mobileMenuBreakpoint ) {
	#panel-content {
		padding:100px 5% 10px 5%;
	
	}
}

.content-category, .content {
	margin-top:30px;
	
	.nav {
		margin-top:10px;
	}
	
	p {
		margin-top:5px;
		margin-bottom:15px;
		
	}
	
	.container-fluid {
		padding-left:0px;
		padding-right:0px;
	}	

	a.image-link-1 {
		font-size:16px;
		font-weight:bold;
		color:@dark-black;
		text-decoration:none;
		
		img {
			display:block;
			margin:auto auto;
		}
	}
	a.image-link-2 {
		img {
			margin-bottom:15px;
		}
	}

		
}

.content-top-margin, .item-page {
	margin-top:20px;
}

// Texts

.homepage-message {
	font-family:Arial, Helvetica, sans-serif;
	font-size:14px;
	font-style: italic;
	//max-width:90%;
	margin: 10px auto 10px auto;
	font-weight: bold;
	text-align:center;
}

form.validated input:required:invalid, input.invalid {
	transition: border-color 1s ease;
	box-shadow: 0px 0px 1.5px 1px @red;
    border-color: @red !important;
}

label.invalid {
	color:@red;
}




