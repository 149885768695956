
.dragAndDropPromoVideo{
  @media (max-width: (800px)) {

    padding-bottom:109%!important;
  }

  @media (min-width: (801px)) {
    
  }
}