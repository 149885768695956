// Make sure we pop over the stupid blue gradient bar..
#carousel-homepage-top-right-minislider, #carousel-6-2b-fit-minislider, #carousel-homepage-top-minislider {
	z-index:2;
	margin-bottom:35px;	
	.carousel-inner .item img{
		margin:auto;
	}
}

#carousel-homepage-top-minislider {
	margin-top:10px;
	margin-bottom:15px;	
	.carousel-inner .item img{
		max-width:94%;
		border-radius: 15px;
		//border:solid 2px black;
	}
	
	#check-it-out {
		color:@white;
		position:absolute;
		bottom:10px;
		width:100%;
		text-align:center;
		#check-it-out-button {
			font-family:@gipsiero;
			
			display:inline-block;
			background-color:@orangy;
			border: 2px solid @dark-black;
			padding:3px 10px 3px 10px;
			border-radius:10px;
		}
		
	}
}


// Another css3 fade support
.carousel-fade {
  .carousel-inner {
    .item {
      opacity: 0;
      transition-property: opacity;
      webkit-transition-property:opacity;
    }

    .active {
      opacity: 1;
    }

    .active.left,
    .active.right {
      left: 0;
      opacity: 0;
      z-index: 1;
    }

    .next.left,
    .prev.right {
      opacity: 1;
    }
  }

  .carousel-control {
    z-index: 2;
  }
}

#carousel-62bfitclub-top-minislider {
	margin-top:20px;
	margin-bottom:15px;	
	.carousel-inner .item img{
		max-width:94%;
		margin:auto;
		
	}
}

.miniSliderTopQuickActions1 {
	display: flex;
	align-items: center;



	 .action1 , .action3 {
		width:31.5%;
		.button {
			margin:0px!important;
			width: 100%;
		}
	 }

	 .action2 {
		width: 37%;
		.button {
			margin:0px!important;
			width: 100%;
		}

	 }

	 @media ( max-width: 600px) {
		flex-direction: column;
		.action1 , .action2, .action3 {
			width: 100%;
		}
	}
}


.miniSliderTopQuickActions{
	display: flex;
	align-items: center;



	 .action1 , .action3 {
		width:37%;
		.button {
			margin:0px!important;
			width: 100%;
		}
	 }

	 .action2 {
		width: 26%;

	 }

	 @media ( max-width: 600px) {
		flex-direction: column;
		.action1 , .action2, .action3 {
			width: 100%;
		}
	}
	 
}