
.menu-bar-desktop
  .menu-bar {
    width:100%;
    background-color: @fresh-blue;
    box-shadow: 0px 3px 10px #000;
    position: absolute;
    z-index:10;

    .menuListStarTitle {
      margin-left: 15px;
      margin-right: 15px;
      position: relative;
      top:-2px;
    }

    .menu-bar-secondary {
      background-color: @menubar-dark-blue;


      .menu-items-secondary {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 5%;
        //max-width: 1230px;
        //margin: auto;
        .menu-item {
          display: block;
          color:@dark-black;
          color:white;
          //padding:5px 30px;
          margin:5px 30px;
          font-size:18px;
          font-weight: bold;;

          @media (max-width:1150px) {
            margin: 5px 15px;
          }
          @media (max-width:950px) {
            margin: 5px 5px;
          }
        }

        .fa-solid {
          margin-right: 15px;
        }


  
      }
    }


    .menu-items {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .menu-item {
        display: block;
        font-weight: bold;
        color:@white;
        margin:10px 10px;
        font-size:20px;
        flex-grow: 1;


      }

      .menu-logo {
        margin:5px 10px 5px 4%;
        flex-grow: 1;
        
        .menu-item {
          font-size:26px;
        }
        
        img { 
          width:200px;
        }
      }

    }

  }

.menu-bar-mobile {
  width:100%;
  background-color: @fresh-blue;
  position: absolute;
  z-index:10;



  .menu-bar {
    
    box-shadow: 0px 3px 10px #000;


    display: flex;
    justify-content: space-around;
    align-items: center;

    .menu-open,.menu-close {
      display: block;
      font-weight: bold;
      color:@white;
      padding:10px 20px 10px 10px;
      font-size:20px;
    }

    .menu-close {
      display:none;
    }

    .menu-logo {
      padding:5px 10px 5px 4%;
      flex-grow: 1;
      img { 
        width:200px;
      }
    }
  }

  .menu-dropdown {
    background-color: @menubar-dark-blue;

    box-shadow: 0px 3px 10px #000;

    display:none;

    padding-top:20px;
    padding-bottom: 20px;

    .fa-solid {
      margin-right: 15px;
    }

    .menuListStarTitle {
      margin-left: 17px;
    }

    .menu-items {
      .menu-item {
        display: block;
        font-weight: bold;
        color:@white;
        padding:10px 10px;
        font-size:20px;
        flex-grow: 1;
        width: 100%;
        text-align: center;


      }
    }

    .menu-items-secondary {
      //background-color: @menubar-dark-blue;
      .menu-item {
        display: block;
        font-weight: bold;
        color:@white;
        padding:10px 10px;
        font-size:18px;
        flex-grow: 1;
        width: 100%;
        text-align: center;
      }
    }


  }



  
}


@media(max-width: @mobileMenuBreakpoint){

  .menu-bar-desktop {
    display: none;
    .menu-bar {
      display: none;
    }

  }
}

@media(min-width: (@mobileMenuBreakpoint+1)){

  .menu-bar-mobile {
    display: none;
  }
}