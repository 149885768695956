.addtocart-bar {
	position:initial;
}

.addtocart-area {
	margin: 0px;
}

div.product {
	border-style:none;
}

.category-view .row .category .spacer h2, .category-view .row .category .spacer h2 a {
	font-size:100%;
	font-size:14px;
	font-family:@open-sans;
	font-color:@dark-black;
	font-weight:bold;
	img {
		padding-top:20px;
		
	}
}

.browse-view h1 {
	font-size:22px;
}

.vm-product-descr-container-0 {
	h2 {
		font-weight: bold;
		font-size: 20px;;
	}
}

.vm-product-descr-container-1 {
	height:auto;
}

.popup .content {
	margin-top:0px;
}

.show_cart a {
	transition: color 1s ease;
	font-family:@open-sans;
	font-size:@font-size-base;
	color:@red;
	//text-decoration:underline;
	float:right;
	margin-right:20px;
	font-weight:bold;
	&:hover,&:active {
			color:darken(@red,15%);
			//text-decoration:underline;
			
	}
}

.vmproduct  {
	img {
		float:left;
		
		}
}
@media(max-width: @screen-xs){
  	 .product {
		float:none;
		width:100%;
	}
}

form#paymentForm {
	fieldset label {
		display:inline;
	}
	
	fieldset {
		margin-bottom:40px;
	}
}

// fix to allow height of thumbnail in browse product
.browseProductImage {
	max-height:inherit;
}

.product-description .title {
	display:none;
}

.vmCartModule   {
	background-color: rgba(243,122,35,0.2);
	padding:10px;
}

.total_products {}

.customProductData {
	font-size: 18px;
}

table.user-details {
	width:100%;
	max-width: 800px;
	margin:auto;
	input:not([type='checkbox']),.chzn-container {
		width: 100%!important;
	}

	.chzn-container {
		a {
			height: 34px;
			padding:5px 15px;
			font-size: 18px;
		}
	}
}

.vmpayment_description, .vmshipment_description {
	font-size: 16px!important;
}

#checkoutFormSubmit{
	padding:10px 10px!important;
	font-size: 28px!important;
}

.vm-payment-header-selected {
	font-size:22px;
}