// fixes
.nav > li > a:hover, .nav > li > a:focus, .nav > li > a:active {
	background-color:none;
}


a {
	transition:color 0.3s linear;
}

.clickable {
	cursor:pointer;
}


.button, .addtocart-button {
	transition: color 1s ease;
	transition: background-color 1s ease;
	
	border-style:solid;
	border-color:@dark-black;
	border-width:3px;
	display:block;
	box-shadow: 5px 4px @dark-black;
	padding:3px 10px!important;
	font-family:@color-button-font;  
	color:@color-button-font-color;	
	font-size:@color-button-font-size;
	line-height:1;
	margin-top:5px;
	margin-bottom:20px;
	
}


 p.buttonfix {
 	width:100%;
 	display:block;
 }

a.button {
	text-decoration: none;
}

.button-orange, .addtocart-button {
	background-color:@orangy;
	&:hover,
      &:focus {
      		background-color:darken(@orangy,5%);
      		color:@color-button-font-color;	
      		
    	}	
}

.button-green {
	background-color:@green;
	&:hover,
      &:focus {
      		background-color:darken(@green,5%);
      		color:@color-button-font-color;	
      		
    	}	
}

.button-dark-green {
	background-color:@dark-green;
	&:hover,
      &:focus {
      		background-color:darken(@dark-green,5%);
      		color:@color-button-font-color;	
      		
    	}	
}

.button-light-blue {
	background-color:@light-blue;
	&:hover,
      &:focus {
      		background-color:darken(@light-blue,5%);
      		color:@color-button-font-color;	
      		
    	}		
}

.button-dark-blue {
	background-color:@dark-blue;
	&:hover,
      &:focus {
      		background-color:darken(@dark-blue,5%);
      		color:@color-button-font-color;	
      		
    	}		
}


.button-yellow{
	background-color:@yellow;
	color:@dark-black;	
	
	&:hover,
      &:focus {
      		background-color:darken(@yellow,5%);
      		color:@dark-black;	
      		
    	}		
}

.button-dark-yellow{
	background-color:@dark-yellow;
	
	&:hover,
      &:focus {
		background-color:darken(@dark-yellow,5%);
		color:@color-button-font-color;	
      		
    	}		
}

.button-red {
	background-color:@red;
	&:hover,
	  &:focus, 
	    &:active {
		background-color:darken(@red,5%);
		color:@color-button-font-color;	
		
	}	
}


.button-float-above {
		position:absolute;
		z-index:2;
	}
	
.button-fix-login-button {
	width:130px;
	text-align:center;
	margin: 10px auto 20px auto;
}	

// Various
#panel-content .button {
	//width:100%;
	text-align:center;
}

 .button-center {
 	width:auto;
 	margin:auto;
 	margin-top:5px;
 	 	margin-bottom:20px;
 	
 	display:inline-block;
 	text-align:center;
 }
 
 .addtocart-button {
 	display:inline;
 }
 
 .button-wide{
 	width:100%;
 }
 
 .button-margin-s {
 	margin-bottom:5px;
 }
 
 // links spots
a.link-spot {
	// dont show the focus
	
	
	img {
			margin-bottom:15px;	display: block; margin-left: auto; margin-right: auto;
		}
	&:hover,
      &:focus {
      	text-decoration:none;
      	outline:0;
      }	
}

.preview {
			transition: color 1s ease;
			font-weight:bold;
		    text-decoration: underline;
			text-align:center;
			color:@red;
			display:block;
			&:hover,&:active {
				color:darken(@red,15%);	
				text-decoration: underline;
						
			}
}

// fix 
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    background-color: transparent;
    color: @orangy;
}

.vm-button-correct {
	.button;
	.button-dark-blue;
	.button-wide;
	transform: background-color 0.3s linear;
	font-family: badhouse-light;
	
	
	&:hover,&:focus {
		font-size:@color-button-font-size;
		font-family: badhouse-light;
	}
}

div.radio.button {
	font-family:badhouse-light;
	max-width:300px;
	max-width:95%;
	margin: auto;
	margin-bottom:20px;

	label {
		width:100%;
		padding-top:3px;
	}

	input[type="radio"] {
		display: none;
	}
	transition: all .2s ease-in-out;

	&.active{
		//background-color:@green;ç
		
		transform: scale(1.1);
	}
}

.donate-button {
	display:flex;
	justify-content: center;
	align-items: center;
	margin-top:5px;
	margin-bottom:20px;
	cursor: pointer;
	padding-top:0px!important;
	padding-bottom:0px!important;
	padding-right:0px!important;
	transition: all .2s ease-in-out;
	&.active{
		//background-color:@green;ç
		
		transform: scale(1.1);
	}

	.donate-amount{
		font-family: @gipsiero;
		font-size: 32px;
		padding:0px 5px 0px 5px;
		//padding:10px;
		flex-basis:25%;
	}

	.donate-description {
		//font-weight:bold;
		border-left:black 3px solid;
		font-family: action-man;//
		flex-basis:75%;
		height:100%;
		padding:10px 10px 10px 10px;
		background-color: wheat;
		color:@dark-black;
	}

}


	#socialShareButtonsWrapper {
		display:flex;
		justify-content: space-around;
		margin:0px 10px 0px 10px;
		flex-wrap: wrap;

	
	}

	.socialShareButtonContent {
		align-items:center;
		display:flex;
		margin:10px 5px;
		font-size: 20px;
		.clickable;
	}
	.shareIcon {
		margin-right: 10px;
	}

	.share-modal-close {
		margin:auto;
	}

	.challengeButton1 {
		text-align: center;
    border-radius: 15px;
    padding: 12px 15px;
    border: 2px solid #358ec4;
    background-color: #358ec4;
    color: wheat;
		text-shadow: 1px 1px 2px rgba(0,0,0,0.3);

		padding: 20px 10%;
    background-image: url('../images/mrTonePointingL.png');
    background-position: 100% bottom;
    background-size:  50%;
		background-repeat: no-repeat;

		box-shadow: 1px 1px 20px rgba(0,0,0,0.2), -1px -1px 20px rgba(0,0,0,0.2);

		background-size: contain;
		@media (max-width:700px) {
			background-blend-mode: soft-light;
		}

		.title1{
			
		}

		.title2{
			font-weight: bold;
		}

		.title3{
			font-family: 'Rock Salt';
			font-size: 24px;;
		}

		.title4 {
			font-style:italic;
		}
	}

	.readmore {
    a {
				white-space: initial!important;
        &:extend(.button);
        &:extend(.button-red);
				&:hover {
					color:white!important;
				}
    }
}