@import url('https://fonts.googleapis.com/css?family=Rock+Salt');


@font-face {
    font-family: 'badhouse-light';
    src: url('../fonts/badhouselight-webfont.eot');
    src: url('../fonts/badhouselight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/badhouselight-webfont.woff2') format('woff2'),
         url('../fonts/badhouselight-webfont.woff') format('woff'),
         url('../fonts/badhouselight-webfont.ttf') format('truetype'),
         url('../fonts/badhouselight-webfont.svg#badhouselight') format('svg');
    font-weight: normal;
    font-style: normal;
		font-display: swap;

}

@font-face {
    font-family: 'gipsiero';
    src: url('../fonts/gipsiero-webfont.eot');
    src: url('../fonts/gipsiero-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gipsiero-webfont.woff2') format('woff2'),
         url('../fonts/gipsiero-webfont.woff') format('woff'),
         url('../fonts/gipsiero-webfont.ttf') format('truetype'),
         url('../fonts/gipsiero-webfont.svg#gipsiero') format('svg');
    font-weight: normal;
    font-style: normal;
		font-display: swap;

}

@font-face {
    font-family: 'open-sans';
    src: url('../fonts/opensans-regular-webfont.eot');
    src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans-regular-webfont.svg#gipsiero') format('svg');
    font-weight: normal;
    font-style: normal;
		font-display: swap;

}

@font-face {
    font-family: 'action-man';
    src: url('../fonts/action_man-webfont.eot');
    src: url('../fonts/action_man-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/action_man-webfont.woff2') format('woff2'),
         url('../fonts/action_man-webfont.woff') format('woff'),
         url('../fonts/action_man-webfont.ttf') format('truetype'),
         url('../fonts/action_man-webfont.svg#gipsiero') format('svg');
    font-weight: normal;
    font-style: normal;
		font-display: swap;

}

a {
	&:hover, &:active, &:focus {
		//text-decoration:none!important;
	}
}

// STYLES

.page-heading {
	font-size:48px;
	color:@dark-black;
	font-family:@gipsiero;
	text-align:center;
	margin-top:10px;
	margin-bottom:0px;	
	text-shadow: 2px 2px 4px lighten(@dark-black,15%);
	@media (max-width:700px) {
		font-size: 40px;
	}
}

.page-heading-large {
	margin-top:10px;
	
}

.page-heading-large-mobile {
		margin-top:10px;		
}

@media(min-width: @screen-sm){

	.page-heading-large-mobile {
		margin-top:40px;		
	}
}

.page-heading-white {
	color:@white;
}


.page-heading-sub-title {
	font-size:24px;
	color:@white;
	font-family:@gipsiero;
	margin-left:55%;
	text-shadow: 2px 2px 4px lighten(@dark-black,15%);		
}

.rockSalt {
	font-family: 'Rock Salt';
}

.badhouse {
	font-family:@badhouse!important;
	
}

.actionman {
	font-family: @action-man!important;
}

.gipsiero-black {
	font-size:@h2-gipsiero-font-size;
	color:@dark-black;
	font-family:@gipsiero;
	line-height:@h2-gipsiero-line-height;
	margin-top:0px;
	margin-bottom:0px;
	text-align:center;
}

.gipsiero-orange {
	font-size:@h2-gipsiero-font-size;
	color:@orangy;
	font-family:@gipsiero;
	line-height:@h2-gipsiero-line-height;
	margin-top:0px;
	margin-bottom:0px;
	text-align:center;
	font-weight: normal;
}

.gipsiero-blue {
	font-size:@h2-gipsiero-font-size;
	color:@light-blue;
	font-family:@gipsiero;
	line-height:@h2-gipsiero-line-height;
	margin-top:0px;
	margin-bottom:0px;
	text-align:center;	
}

.gipsiero-green {
	font-size:@h2-gipsiero-font-size;
	color:@green;
	font-family:@gipsiero;
	line-height:@h2-gipsiero-line-height;
	margin-top:0px;
	margin-bottom:0px;
	text-align:center;	
}

.gipsiero-red {
	font-size:@h2-gipsiero-font-size;
	color:@red;
	font-family:@gipsiero;
	line-height:@h2-gipsiero-line-height;
	margin-top:0px;
	margin-bottom:0px;
	text-align:center;	
}

.open-sans-orange {
	font-size:@font-size-large;
	color:@orangy;
	font-family:@open-sans;
	font-weight:bold;
}

.open-sans-blue {
	font-size:@font-size-large;
	color:@light-blue;
	font-family:@open-sans;
	font-weight:bold;
}

h2.h2-actionman {
	font-family:@action-man;
	color:@orangy;
	font-size:35px;
	margin-bottom:0px;
	font-weight:normal;
}

h3.h3-actionman {
	font-family:@action-man;
	color:@orangy;
	font-size:25px;
	margin-bottom:0px;
	font-weight:normal;
}

h3.h3black {
	font-family:@open-sans;
	color:@dark-black;
	font-size:20px;
	margin-bottom:0px;
	font-weight:bold;
}

a.news {
	font-family:@open-sans;
	color:@dark-blue;
	text-decoration:underline;
	font-size:14px;
	margin-bottom:0px
}
.category-all-videos {
	h2 {
		font-family:@action-man;
		color:@dark-blue;
		font-size:22.5px;
		margin-bottom:0px
	}
}


h2.teacher-training {
	font-size:22.5px;
		
	.collapsed .glyphicon-arrow-down {
			webkit-transition: all 0.5s ease;
			transition: all 0.5s ease;
			
			//webkit-transition: color 0.5s ease;
			//transition: color 0.5s ease;
			
    		-ms-transform: rotate(0deg); /* IE 9 */
    		-webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
			transform: rotate(0deg);
			color:lighten(@orangy,15%);
			  
			
	
	}
	
	.glyphicon-arrow-down {
			webkit-transition: all 0.5s ease;
			transition: all 0.5s ease;
			
			//webkit-transition: color 0.5s ease;
			//transition: color 0.5s ease;
			
			-ms-transform: rotate(-90deg); /* IE 9 */
    		-webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
		    transform: rotate(-90deg);
		    color:@orangy;
	
	}
	
}

h4 {
	font-family:@action-man;
	color:@orangy;
	font-size:22.5px;
	margin-bottom:0px
}


h4.faq {
	margin-bottom:30px;
	a { 
		transition: color 1s ease;
		color:@dark-blue;

		&.collapsed {
			color:@dark-blue;		
		}
		
		&:hover,&:active {
			color:darken(@dark-blue,15%);
		}
	}

	
}

h4.popup-product {
	margin-bottom:20px;
	font-size:@font-size-base;
	color:@dark-black;
}
h5 {
	margin-bottom:0px;
	font-size:@font-size-base;
}

.readmore {
	transition: color 1s ease;
	font-family:@open-sans;
	font-size:@font-size-base;
	color:@red;
	text-decoration:underline;
	float:right;
	margin-right:20px;
	&:hover,&:active {
			color:darken(@red,15%);
			text-decoration:underline;
			
	}
}

.download {
	transition: color 1s ease;
	font-family:@open-sans;
	font-size:@font-size-base;
	color:@dark-blue;
	text-decoration:underline;
	&:hover,&:active {
			color:darken(@dark-blue,15%);
			text-decoration:underline;			
	}
}

.start-now-area {
	text-align:center;
}

.slider-caption {
	dispay:block;
	width:100%;
	text-align:center;
	text-decoration:underline;
}

#footer h3 {
	color:@white;
}

.white {
	color:@white!important;
}

.green {
	color:@green;
}

.black {
	color:@dark-black;	
}

.blue {
	color:@light-blue;
}

.red {
	color:@red;
}

.orange {
	color:@orangy;
}

.dark-blue {
	color:@dark-blue;
}

.no-bmargin {
	margin-bottom:0px;	
}

.add-bmargin {
	margin-bottom:10px;
}
.add-bmargin-m {
	margin-bottom:15px;
}

.add-bmargin-l {
	margin-bottom:20px;
}

.kidsclub-title {
	font-family:badhouse-light;
	font-size:36px;
}

.t-center {
	text-align: center;
}

.t-italic {
	font-style: italic;
}

.t-underline {
	text-decoration: underline;
}

.t-small-size,.t-size-10 {
	font-size:10px;
}

.t-size-16 {
	font-size:16px;
}

.t-medium-size, .t-size-18 {
	font-size:18px;
}

.text-color-light-blue { 
	color: @light-blue!important;
}

.text-color-orangy, .text-color-orange  {
	color:@orangy!important;
}

.text-color-dark-black {
	color:@dark-black!important;
}

.text-color-grass-green {
	color:@green!important;
}

.bold {
	font-weight: bold;
}

/*
.listStar:before {
	content:"\e006";
	font-family: 'Glyphicons Halflings';
	color: #f1c606 ;

}
*/

.listStar:before {

	content: url(../images/OtherCharacters/star-bullet.svg); 
	position: relative;
	top: 6px
}

.listStarInline {
	display: inline-block;
	background-image: url(../images/OtherCharacters/star-bullet.svg);
}

.listStarDouble:before,.listStarDouble:after {

	content: url(../images/OtherCharacters/star-bullet.svg); 
	position: relative;
	top: 6px
}

ul.starred, ol.starred, ul, ol {
	list-style-image:  url(../images/OtherCharacters/star-bullet.svg); 

	li {
		//display:block;
		padding-left: 5px;
	}

}

.content {
	/*
	li {
		display: block;
	}
	
	li:before {
		//Using a Bootstrap glyphicon as the bullet point
		content:"\e006";
		font-family: 'Glyphicons Halflings';
		font-size: 14px;
		float: left;
		margin-top: 1px;
		margin-left: -17px;
		color: #f1c606 ;
	}
	*/
}

.t-no-break,.t-no-wrap {
	white-space:nowrap;
}


.horizontalSubmenuLink {
	text-decoration: underline;
	&:hover {
		text-decoration: underline!important;
		//text-decoration-color: black!important;


	}
}

li.active .horizontalSubmenuLink {
	color:black!important;
	text-decoration-color: @orangy;
  &:hover{
		text-decoration-color: @orangy!important;

	}
}