#videoModal {
	.modal-content {
		background-color:transparent;
	}
	.modal-body {
		padding:0px;
		margin-bottom:-6px;
	}
	
	#modal-video-container {
		width:100%;
	}
	
	.embed-responsive-16by9 {
    	//padding-bottom: 57.5%;
	}
	
	#video-wrapper {
		background-image:url('../images/mr-tone-up-logo.png');
		background-repeat: no-repeat;
    	//background-attachment: fixed;
   	    background-position: center; 
	}

}

.ringtones audio {
	width:100%;
}

.my_youtube_video_embed,
span[data-mce-p-class="my_youtube_video_embed"] iframe {
	width: 100%;
	height: auto;
	aspect-ratio: 16/9;
	margin: 10px 0px;
}

span[data-mce-p-class="my_youtube_video_embed"] {
	display: block!important;;
	width: 90%!important;;
	margin: 0px auto!important;;
	border:none!important;
}