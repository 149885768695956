.FundraiserBar {
  .target {
    font-weight: bold;
    font-size: 28px;
    text-align: right;
  }

  .raised {
    text-align: right;
    .t-no-break;
    .percentage {
      font-weight: bold;
    }
    
  }

  .barContainer {
    width:100%;
    background-color: rgb(209, 209, 209);
    padding:5px;
    display:flex;
    align-items: center;
    .animatedBar {

      background-color: rgb(248, 0, 112);
      transition: width 2s;
      height: 20px;
    }
  }

  
}