.adobePDFLetterSize {
  padding-bottom: calc((9 / 16) * 100%); 

  @media (max-width:700px) {
    padding-bottom: calc((11 / 8.5) * 100%); 
  }
  position: relative;
  > div {
    position: absolute; 
    height: 100%; 
    width: 100%;
  }
}