

// LEFT NAV BAR

.navbar-right {

	.nav-stacked > li > a.button{
		text-align:center;
		font-family:@right-menu-button-font;
		font-size:@right-menu-button-font-size;
	}	
}

.navbar-left,.navbar-right {
  //background-color: @navbar-default-bg;
  //border-color: @navbar-default-border;
   float:none!important;
   z-index:2;
   @media(min-width: @screen-desktop){
   		margin:auto;
   		max-width:85%;   	
   } 

	
  .navbar-brand {
    color: @navbar-default-brand-color;
    &:hover,
    &:focus {
      color: @navbar-default-brand-hover-color;
      background-color: @navbar-default-brand-hover-bg;
    }
  }

  .navbar-text {
    color: @navbar-default-color;
  }

	

  .nav-stacked {
  	  	
  	> li > a.no-button {
    	font-family:@left-menu-heading-font;
    	font-size:@left-menu-heading-font-size;	
    	color: @left-menu-link-color;
    	
    }
    
    > li > a.button {
    	//font-family:@color-button-font;
    	//font-size:@color-button-font-size;	
    	//color:@color-button-font-color;	
    	
    }
    
    > li > ul > li > a.no-button {
    	font-family:@left-menu-sub-heading-font;
    	font-size:@left-menu-sub-heading-font-size;	
    	color: @left-menu-link-color;
    	
    }
    
    li {
    	list-style-type:none;
    	
    }
    
    li > a.no-button {
    	padding:0px;
    	transition: color 0.5s ease;
		
      &:hover,
      &:focus {
        color: @orangy;//@left-menu-link-hover-color;
        background-color: @navbar-default-link-hover-bg;
        text-decoration:none;
        
      }
    }
    
    // buttons active current color of left menu
    > .active > a.no-button, > .active li.active > a.no-button {
      &,
      &:hover,
      &:focus {
        color: @orangy;//@left-menu-link-active-color;
        background-color: @navbar-default-link-active-bg;
      }
    }
    
    > .disabled > a.no-button {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
        background-color: @navbar-default-link-disabled-bg;
      }
    }
    
    li > a.button {
    	padding:0px;
    	//transition: background-color 0.5s ease;
		
      &:hover,
      &:focus {
        //color: @color-button-font-color;
        text-decoration:none;
        
      }
    }
    > .active > a.button {
      &,
      &:hover,
      &:focus {
        color: @color-button-font-color;
        text-decoration:none;
      }
     }
      
      > .active > a.button-yellow {
      &,
      &:hover,
      &:focus {
        color: @dark-black;;
        text-decoration:none;
      }
    }

  }
  
  .navbar-toggle {
    border-color: @navbar-default-toggle-border-color;
    &:hover,
    &:focus {
      background-color: @navbar-default-toggle-hover-bg;
    }
    .icon-bar {
      background-color: @navbar-default-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: @navbar-default-border;
  }

  // Dropdown menu items
  .navbar-nav {
    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @navbar-default-link-active-bg;
        color: @navbar-default-link-active-color;
      }
    }

    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: @navbar-default-link-color;
          &:hover,
          &:focus {
            color: @navbar-default-link-hover-color;
            background-color: @navbar-default-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-active-color;
            background-color: @navbar-default-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-default-link-disabled-color;
            background-color: @navbar-default-link-disabled-bg;
          }
        }
      }
    }
  }


  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: @navbar-default-link-color;
    &:hover {
      color: @navbar-default-link-hover-color;
    }
  }

  .btn-link {
    color: @navbar-default-link-color;
    &:hover,
    &:focus {
      color: @navbar-default-link-hover-color;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: @navbar-default-link-disabled-color;
      }
    }
  }
}

// THE middle NAV

.nav-middle {
	a {
					transition: color 0.5s ease;
		padding:0px 5px;
	}
	font-family:@open-sans;
	font-size:18px;
	font-weight:bold; 
	margin-top:15px;
	margin-bottom:25px;
	> li > a {

		@media(min-width: @screen-sm){
		     border-left-style: solid;
		     border-width:1px;
		     border-color:@dark-black;
		     border-radius:0px;
		}     
		&:hover,&:active {
			color:@orangy;
		}
		     
	}
	> li:first-child > a {
			 border-left-style: none;
		     border-width:0px;
	}
	
	> li.active > a, li.current > a {
			 color:@orangy;
			 background-color:transparent;
			 &:hover, &:active {
	            color:@orangy;
			 	background-color:transparent;
			 }
		
	}   

}
