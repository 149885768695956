.videoslider {
	video {
		width:100%;
		padding:10px 15px;
	}
	
}

#videosCarousel  {
	
	margin-top:20px;
	
	[class*="col-"] {

		height:200px;
		overflow:none;	
	}
	
	.row {
		padding: 0px 20px;
	}
	
	.carousel-control {
		width:20px;
		color:@orangy;
		opacity:0.9;

	}
	
	.left.carousel-control, .right.carousel-control {
		filter:none;
	}
	
	.carousel-control.right, .carousel-control.left {
		background-image:none;
	}
	
	.videoslider_title {
		font-family:action-man;
		font-size:22px;
		color:@orangy;
		text-align:center;
	}
	
	.videoslider_text {
		font-family:action-man;
		color:@dark-blue;
		text-align:center;
	}
}